import { FC } from 'react';
import { Disclosure } from '@headlessui/react';
import { PlusIcon, MinusIcon } from '@heroicons/react/24/outline';
import ChevronDownIcon from 'components/icons/ChevronDownIcon';
import ChevronUpIcon from 'components/icons/ChevronUpIcon';
import TextLabel from 'components/icons/labels/TextLabel';
import useClassNames from 'helpers/hooks/useClassNames';
import { AccordionProps } from '.';
import LoadingIcon from '../button/loadingIcon';
import Typography from '../typography';

type AccordionButtonProps = AccordionProps & {
  open: boolean;
  loading?: boolean;
  buttonWrapperClassName?: string;
  buttonClassName?: string;
  dataTest?: string | null;
};

const AccordionButton: FC<AccordionButtonProps> = ({
  open,
  loading,
  buttonWrapperClassName,
  buttonClassName,
  openSectionTitle,
  closedSectionTitle,
  facetHits,
  collapsedLabel,
  variant,
  iconClassName,
  dataTest = null,
  as,
}) => {
  const buttonClassNames = useClassNames(['w-full flex justify-between', buttonClassName]);
  let buttonWrapperFacetClassName = '';

  if (variant === 'facet') {
    buttonWrapperFacetClassName = open ? 'border-0' : 'border-b border-b-neutral-2';
  }

  return (
    <Disclosure.Button
      data-test={dataTest}
      className={`${buttonWrapperClassName} ${buttonWrapperFacetClassName} w-full`}
    >
      <div className={buttonClassNames}>
        <div className="flex items-center gap-12">
          <Typography className="self-center transition" as={as}>
            {open ? openSectionTitle : closedSectionTitle}
          </Typography>
          {variant === 'facet' && loading ? <LoadingIcon className="fill-neutral-4" /> : null}
        </div>
        <div className="flex items-center gap-8">
          {!open && collapsedLabel && <p className="font-medium text-primary-black">{collapsedLabel}</p>}
          {variant === 'facet' && facetHits && !open ? (
            <div className="pl-12 pr-4">
              <TextLabel variant="facet-hits">
                <span>{facetHits}</span>
              </TextLabel>
            </div>
          ) : null}
          {variant === 'arrow' || variant === 'facet' ? (
            <span className={`${open ? 'rotate-180' : ''} ${iconClassName} transition`}>
              <ChevronDownIcon />
            </span>
          ) : variant === 'arrow-filter' ? (
            <div className="flex h-32 w-32 items-center justify-center rounded-full bg-base-accent-2">
              {open ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </div>
          ) : !open ? (
            <PlusIcon width={17.5} strokeWidth={2} className={iconClassName} />
          ) : (
            <MinusIcon width={17.5} strokeWidth={2} className={iconClassName} />
          )}
        </div>
      </div>
    </Disclosure.Button>
  );
};

export default AccordionButton;
