import { useCallback } from 'react';
import { SDKResponse } from '@commercetools/frontend-sdk';
import { Category, Result } from 'shared/types/product';
import { Inventory } from 'shared/types/product/inventory';
import { ProductQuery } from 'shared/types/query/ProductQuery';
import useSWR from 'swr';
import { sdk } from 'sdk';
import { revalidateOptions } from 'frontastic';
import { UseProductReturn } from './types';

const useProduct = (): UseProductReturn => {
  const extensions = sdk.composableCommerce;

  // We pass empty object to fecth the whole category tree
  const categoriesResults = useSWR(
    '/action/product/queryCategories',
    () => extensions.product.queryCategories({}),
    revalidateOptions,
  );

  const categoriesLoading = categoriesResults.isLoading;

  const categories = (categoriesResults.data?.isError ? [] : (categoriesResults.data?.data?.items as Category[])) ?? [];

  const query = useCallback(async (productQuery: ProductQuery) => {
    const extensions = sdk.composableCommerce;

    const res = await extensions.product.query({ limit: productQuery.limit, query: productQuery.query });

    return res;
  }, []);

  const suggestions = useCallback(async (productQuery: ProductQuery) => {
    if (!productQuery.query) return {} as SDKResponse<Result>;
    const response = await sdk.callAction({
      actionName: 'factFinder/suggestions',
      query: { query: productQuery.query, limit: productQuery.limit ?? 6 },
    });
    return (response.isError ? {} : response) as SDKResponse<Result>;
  }, []);

  const getInventory = useCallback(async (sku: string) => {
    const response = await sdk.callAction({ actionName: 'product/getInventory', query: { sku } });
    return (response.isError ? {} : response.data) as Inventory;
  }, []);

  return { categories, categoriesLoading, query, getInventory, suggestions };
};

export default useProduct;
