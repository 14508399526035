import React, { ReactNode, useEffect, useRef } from 'react';
import { Disclosure, Transition } from '@headlessui/react';
import useClassNames from 'helpers/hooks/useClassNames';
import AccordionButton from './AccordionButton';
import { TypographyProps } from '../typography/types';

export interface AccordionProps extends Pick<TypographyProps, 'as'> {
  index?: number;
  variant?: 'arrow' | 'plusAndMinus' | 'arrow-filter' | 'facet';
  accordionListLength?: number;
  className?: string;
  openSectionTitle?: string;
  closedSectionTitle?: string;
  iconClassName?: string;
  buttonClassName?: string;
  buttonWrapperClassName?: string;
  panelClassName?: string;
  collapsedLabel?: string;
  customOpenButton?: ReactNode;
  customClosedButton?: ReactNode;
  onClick?: () => void;
  defaultOpen?: boolean;
  startOpen?: boolean;
  ref?: React.RefObject<HTMLDivElement>;
  dataTest?: string | null;
  facetHits?: string | number;
  loading?: boolean;
}

const Accordion = (
  {
    variant = 'arrow',
    closedSectionTitle,
    openSectionTitle = closedSectionTitle,
    children,
    className = '',
    iconClassName = '',
    buttonClassName = '',
    buttonWrapperClassName = '',
    panelClassName = '',
    collapsedLabel,
    customClosedButton,
    customOpenButton = customClosedButton,
    defaultOpen = false,
    startOpen = false,
    onClick,
    dataTest = null,
    as = 'p',
    facetHits,
    loading,
  }: React.PropsWithChildren<AccordionProps>,
  ref: React.ForwardedRef<HTMLDivElement>,
) => {
  const panelClassNames = useClassNames([panelClassName]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const btnRef = useRef<any>(null);

  useEffect(() => {
    if (startOpen) {
      const openBtn = btnRef?.current?.firstChild;
      if (openBtn?.dataset.headlessuiState !== 'open') {
        openBtn.click();
      }
    }
  }, [startOpen]);

  return (
    <div className={className} onClick={onClick} ref={ref}>
      <Disclosure defaultOpen={defaultOpen}>
        {({ open }) => (
          <>
            {(open && !customOpenButton) || (!open && !customClosedButton) ? (
              <div ref={btnRef}>
                <AccordionButton
                  open={open}
                  loading={loading}
                  variant={variant}
                  collapsedLabel={collapsedLabel}
                  buttonClassName={buttonClassName}
                  iconClassName={iconClassName}
                  openSectionTitle={openSectionTitle}
                  closedSectionTitle={openSectionTitle}
                  facetHits={facetHits}
                  buttonWrapperClassName={buttonWrapperClassName}
                  dataTest={dataTest}
                  as={as}
                />
              </div>
            ) : (
              <Disclosure.Button className={buttonClassName} data-test={dataTest}>
                {open ? customOpenButton : customClosedButton}
              </Disclosure.Button>
            )}

            <Transition
              enter="transition duration-150 ease-out"
              enterFrom="transform scale-y-95 opacity-0"
              enterTo="transform scale-y-100 opacity-100"
              leave="transition duration-100 ease-out"
              leaveFrom="transform scale-y-100 opacity-100"
              leaveTo="transform scale-y-95 opacity-0"
              unmount={false}
            >
              <Disclosure.Panel className={panelClassNames} unmount={false}>
                {children}
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default React.forwardRef<HTMLDivElement, React.PropsWithChildren<AccordionProps>>(Accordion);
